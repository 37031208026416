@import "@/styles/utils.scss";

.videoCampaignContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  color: white;
  border-radius: 20px;
  padding-inline: 5px;
  gap: 20px;

  @include mq(L) {
    grid-template-columns: repeat(2, 1fr);
  }
}