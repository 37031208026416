.container {
  display: flex;
  gap: 10px;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  position: relative;

  img {
    box-shadow: 0px 1px 1px black;
    height: auto;
  }
}

.right {
  display: flex;
  flex-direction: column;
  width: 100%;
}
