@import "@/styles/utils.scss";

.insightContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @include mq(L) {
    grid-template-columns: repeat(2, 1fr);
  }
}
