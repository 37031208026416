@import "@/styles/utils.scss";

$BREAK: XL;

.homeContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include mq($BREAK) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(12, 1fr);
  }
}

.mainSlider {
  @include mq($BREAK) {
    grid-column: 1 / 9;
    grid-row: 1/2;
  }
}

.updates {
  @include mq($BREAK) {
    grid-column: 1/9;
    grid-row: 2/3;
  }
}

.events {
  min-height: 100px;
  @include mq($BREAK) {
    grid-column: 9 / -1;
    grid-row: 1/3;
  }
}
